// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

	--ion-color-primary: #094061;
	--ion-color-primary-rgb: 9,64,97;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #083855;
	--ion-color-primary-tint: #225371;

	--ion-color-secondary: #84cab3;
	--ion-color-secondary-rgb: 132,202,179;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #74b29e;
	--ion-color-secondary-tint: #90cfbb;

	--ion-color-tertiary: #c29cc2;
	--ion-color-tertiary-rgb: 194,156,194;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #ab89ab;
	--ion-color-tertiary-tint: #c8a6c8;


	--ion-color-success: #02bb09;
	--ion-color-success-rgb: 2, 187, 9;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #02a508;
	--ion-color-success-tint: #1bc222;

	--ion-color-warning: #f3a101;
	--ion-color-warning-rgb: 243, 161, 1;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #d68e01;
	--ion-color-warning-tint: #f4aa1a;

	--ion-color-danger: #db0000;
	--ion-color-danger-rgb: 219, 0, 0;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #c10000;
	--ion-color-danger-tint: #df1a1a;

	--ion-color-light: #eae9e9;
	--ion-color-light-rgb: 234, 233, 233;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #cecdcd;
	--ion-color-light-tint: #ecebeb;

	--ion-color-medium: #616167;
	--ion-color-medium-rgb: 97, 97, 103;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #55555b;
	--ion-color-medium-tint: #717176;

	--ion-color-dark: #43434a;
	--ion-color-dark-rgb: 67, 67, 74;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #3b3b41;
	--ion-color-dark-tint: #56565c;

	--ion-color-light-background: #f3f3f3;
	--ion-color-light-background-rgb: 243, 243, 243;
	--ion-color-light-background-contrast: #000000;
	--ion-color-light-background-contrast-rgb: 0, 0, 0;
	--ion-color-light-background-shade: #d6d6d6;
	--ion-color-light-background-tint: #f4f4f4;

	--ion-toolbar-background: var(--ion-color-primary);
	--ion-toolbar-color: var(--ion-color-primary-contrast);

	--highlight-color-focused: var(--ion-color-light);
	--highlight-color-valid: var(--ion-color-medium);
	--highlight-color-invalid: var(--ion-color-danger);

	--ion-text-color: #9EA8B0;
	--ion-text-color-rgb: 158, 168, 176;

	--ion-text-color-dark: #43434a;
	--ion-text-color-dark-rgb: 67, 67, 74;

	--ion-color-text-white: #ffffff;
	--ion-color-text-white-rgb: 255, 255, 255;



	/*vivascore colours*/
	--viva-border-radius: 999px;
	--viva-control-height: 54px;
	--viva-control-border-width: 3px;
	--viva-control-margin-top: 20px;
	--font-family-nunito: 'Roboto', sans-serif;
	--font-family: var(--font-family-nunito); // Fixed the variable reference
	--font-family-poppins: 'Arial', sans-serif;

	--ion-color-blue: #26548e;
	--ion-color-blue-rgb: 38,84,142;
	--ion-color-blue-contrast: #ffffff;
	--ion-color-blue-contrast-rgb: 255,255,255;
	--ion-color-blue-shade: #214a7d;
	--ion-color-blue-tint: #3c6599;

	--ion-color-dark-red: #9C0000;
	--ion-color-dark-red-rgb: 156, 0, 0;
	--ion-color-dark-red-contrast: #ffffff;
	--ion-color-dark-red-contrast-rgb: 255, 255, 255;
	--ion-color-dark-red-shade: #890000;
	--ion-color-dark-red-tint: #a61a1a;
	--ion-color-dark-red-disabled: #B36666;

	--ion-color-light-red: #DB0000;
	--ion-color-light-red-rgb: 219, 0, 0;
	--ion-color-light-red-contrast: #ffffff;
	--ion-color-light-red-contrast-rgb: 255, 255, 255;
	--ion-color-light-red-shade: #c10000;
	--ion-color-light-red-tint: #df1a1a;
	--ion-color-light-red-disabled: #D6A4A4;

	--ion-color-orange: #F3A101;
	--ion-color-orange-rgb: 243, 161, 1;
	--ion-color-orange-contrast: #000000;
	--ion-color-orange-contrast-rgb: 0, 0, 0;
	--ion-color-orange-shade: #d68e01;
	--ion-color-orange-tint: #f4aa1a;
	--ion-color-orange-disabled: #EBCFA5;

	--ion-color-yellow: #F3CC00;
	--ion-color-yellow-rgb: 243, 204, 0;
	--ion-color-yellow-contrast: #000000;
	--ion-color-yellow-contrast-rgb: 0, 0, 0;
	--ion-color-yellow-shade: #d6b400;
	--ion-color-yellow-tint: #f4d11a;
	--ion-color-yellow-disabled: #E8D7A4;

	--ion-color-light-green: #89ED8D;
	--ion-color-light-green-rgb: 137, 237, 141;
	--ion-color-light-green-contrast: #000000;
	--ion-color-light-green-contrast-rgb: 0, 0, 0;
	--ion-color-light-green-shade: #79d17c;
	--ion-color-light-green-tint: #95ef98;
	--ion-color-light-green-disabled: #CDEACE;

	--ion-color-medium-green: #02BB09;
	--ion-color-medium-green-rgb: 2, 187, 9;
	--ion-color-medium-green-contrast: #000000;
	--ion-color-medium-green-contrast-rgb: 0, 0, 0;
	--ion-color-medium-green-shade: #02a508;
	--ion-color-medium-green-tint: #1bc222;
	--ion-color-medium-green-disabled: #A4D6A4;

	--ion-color-dark-green: #008005;
	--ion-color-dark-green-rgb: 0, 128, 5;
	--ion-color-dark-green-contrast: #ffffff;
	--ion-color-dark-green-contrast-rgb: 255, 255, 255;
	--ion-color-dark-green-shade: #007104;
	--ion-color-dark-green-tint: #1a8d1e;
	--ion-color-dark-green-disabled: #A4CBA4;

	--ion-color-cyan-medium: #f0ecf6;
	--ion-color-cyan-medium-rgb: 240,236,246;
	--ion-color-cyan-medium-contrast: #000000;
	--ion-color-cyan-medium-contrast-rgb: 0,0,0;
	--ion-color-cyan-medium-shade: #d3d0d8;
	--ion-color-cyan-medium-tint: #f2eef7;

	--ion-color-cyan-dark: #c198c9;
	--ion-color-cyan-dark-rgb: 193,152,201;
	--ion-color-cyan-dark-contrast: #000000;
	--ion-color-cyan-dark-contrast-rgb: 0,0,0;
	--ion-color-cyan-dark-shade: #aa86b1;
	--ion-color-cyan-dark-tint: #c7a2ce;
	
	--viva-color-poor: var(--ion-color-dark-red);
	--viva-color-poor-disabled: var(--ion-color-dark-red-disabled);

	--viva-color-fair: var(--ion-color-orange);
	--viva-color-fair-disabled: var(--ion-color-orange-disabled);

	--viva-color-average: var(--ion-color-yellow);
	--viva-color-average-disabled: var(--ion-color-yellow-disabled);

	--viva-color-good: var(--ion-color-light-green);
	--viva-color-good-disabled: var(--ion-color-light-green-disabled);

	--viva-color-excellent: var(--ion-color-dark-green);
	--viva-color-excellent-disabled: var(--ion-color-dark-green-disabled);

	--viva-graph-text-color: var(--ion-color-medium);

	--viva-home-background-gradient: var(--ion-color-tertiary);



}

@media (prefers-color-scheme: dark) {
	/*
   * Dark Colors
   * -------------------------------------------
   */

	body {


		--ion-color-light: #43434a;
		--ion-color-light-rgb: 67, 67, 74;
		--ion-color-light-contrast: #ffffff;
		--ion-color-light-contrast-rgb: 255, 255, 255;
		--ion-color-light-shade: #3b3b41;
		--ion-color-light-tint: #56565c;

		--ion-color-medium: #616167;
		--ion-color-medium-rgb: 97, 97, 103;
		--ion-color-medium-contrast: #ffffff;
		--ion-color-medium-contrast-rgb: 255, 255, 255;
		--ion-color-medium-shade: #55555b;
		--ion-color-medium-tint: #717176;

		--ion-color-dark: #eae9e9;
		--ion-color-dark-rgb: 234, 233, 233;
		--ion-color-dark-contrast: #000000;
		--ion-color-dark-contrast-rgb: 0, 0, 0;
		--ion-color-dark-shade: #cecdcd;
		--ion-color-dark-tint: #ecebeb;
	}

	/*
   * iOS Dark Theme
   * -------------------------------------------
   */

	.ios body {
		--ion-background-color: #1d1e25;
		--ion-background-color-rgb: 0, 0, 0;



		--ion-color-step-50: #0d0d0d;
		--ion-color-step-100: #1a1a1a;
		--ion-color-step-150: #262626;
		--ion-color-step-200: #333333;
		--ion-color-step-250: #404040;
		--ion-color-step-300: #4d4d4d;
		--ion-color-step-350: #595959;
		--ion-color-step-400: #666666;
		--ion-color-step-450: #737373;
		--ion-color-step-500: #808080;
		--ion-color-step-550: #8c8c8c;
		--ion-color-step-600: #999999;
		--ion-color-step-650: #a6a6a6;
		--ion-color-step-700: #b3b3b3;
		--ion-color-step-750: #bfbfbf;
		--ion-color-step-800: #cccccc;
		--ion-color-step-850: #d9d9d9;
		--ion-color-step-900: #e6e6e6;
		--ion-color-step-950: #f2f2f2;

		--ion-item-background: #1d1e25;

		--ion-card-background: #1d1e25;
	}

	.ios ion-modal {
		--ion-background-color: var(--ion-color-step-100);
		--ion-toolbar-background: var(--ion-color-primary);
		--ion-toolbar-border-color: var(--ion-color-step-250);
	}


	/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

	.md body {
		--ion-background-color: #1d1e25;
		--ion-background-color-rgb: 18, 18, 18;


		--ion-border-color: #222222;

		--ion-color-step-50: #1e1e1e;
		--ion-color-step-100: #2a2a2a;
		--ion-color-step-150: #363636;
		--ion-color-step-200: #414141;
		--ion-color-step-250: #4d4d4d;
		--ion-color-step-300: #595959;
		--ion-color-step-350: #656565;
		--ion-color-step-400: #717171;
		--ion-color-step-450: #7d7d7d;
		--ion-color-step-500: #898989;
		--ion-color-step-550: #949494;
		--ion-color-step-600: #a0a0a0;
		--ion-color-step-650: #acacac;
		--ion-color-step-700: #b8b8b8;
		--ion-color-step-750: #c4c4c4;
		--ion-color-step-800: #d0d0d0;
		--ion-color-step-850: #dbdbdb;
		--ion-color-step-900: #e7e7e7;
		--ion-color-step-950: #f3f3f3;

		--ion-item-background: #1e1e1e;

		--ion-toolbar-background: var(--ion-color-primary);

		--ion-tab-bar-background: #1f1f1f;

		--ion-card-background: #1e1e1e;
	}
}