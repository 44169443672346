/*****************************************************
Buttons
*****************************************************/
ion-button::part(native) {
    min-height: var(--viva-control-height);
    border-radius: var(--viva-border-radius);
    color: white;
    --color: white;

}

ion-button {
    border-radius: var(--viva-border-radius);
    min-height: var(--viva-control-height);
    font-family: var(--font-family-nunito);
    font-weight: bold;
    text-transform: none;
    --box-shadow: none;
    font-size: 1.4rem;
}


ion-button.btn-outline::part(native) {
    color: var(--ion-color-secondary);
    --color: var(--ion-color-secondary);
}

/*****************************************************
Busy Loader
*****************************************************/
.loading-wrapper {
    border-radius: 50px !important;
}
