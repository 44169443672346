@media (prefers-color-scheme: light) {
	.md body {
		--browser-background-color: var(--ion-color-primary-tint);
		--ion-background-color: #ffffff;
		--ion-card-background: var(--ion-background-color);
	}
}

@media (prefers-color-scheme: dark) {
	.md body {
		--browser-background-color: #000000;
		--ion-background-color: #1d1e25;
		--ion-card-background: var(--ion-background-color);
	}
}