html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--browser-background-color) !important;
}

.top-margin {
  margin-top: var(--viva-control-margin-top) !important;
}

.viva-page {
  > :first-child {
    margin-top: calc(env(safe-area-inset-top) +  (var(--viva-control-margin-top) / 2));
    /* Using a fixed value for testing */
  }

}

body.web-platform {
  display: flex;
  justify-content: center;
  align-items: center;
}

body.web-platform app-root {
 // max-width: 768px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}


body.web-platform .ion-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  margin: 0;

}

/* Media query for rounded borders */
@media (min-width: 768px) {
  body.web-platform app-root {
    border-radius: 20px;
    height: calc(100% - 20px);
  }
}

.layout-back-button {
  position: absolute;
 // top: var(--viva-control-margin-top);//calc(env(safe-area-inset-top) + (var(--viva-control-margin-top) / 2));
  left: -0.5em;
  z-index: 1;
}



.layout-page-container {
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  position: relative;
}

.layout-page-logo {
  display: flex;
  justify-content: center;

  ion-img {
    max-height: 100px;
    max-width: 180px;
  }
}


.layout-form-container {
  text-align: left;
}

.layout-title-spacer{
  margin-top: var(--viva-control-margin-top);
}
