/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import 'src/theme/layout.scss';
@import './variables.scss';

//v2 overrides
@import 'src/theme/v2/fonts.scss';
@import 'src/theme/v2/variables.scss';
@import 'src/theme/v2/app-layout.scss';
@import 'src/theme/v2/typography.scss';
@import 'src/theme/v2/controls.scss';






.custom-info-modal {
  --height: auto;
  --width: 85%;
  --max-height: 90%;
  --border-radius: 16px;
  --box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.ai-citation-modal {
  --height: 85%;
  --width: 85%;
  --border-radius: 16px;
  --box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

}


ion-modal#scan-tip-modal {
  --width: 80%;
  --min-width: 250px;
  --height: 500px;
  --border-radius: 16px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  .model-content {
    padding: 20px;
    text-align: center;
    margin-bottom: 10px;

  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
  }

  .info-icon {
    margin-top: 15px;
    width: 92px;
    height: 92px;
    margin-bottom: 24px;
  }

  .message {
    text-align: center;
    font-size: 1.12em;
    line-height: normal;
  }

  ul {
    text-align: start;
  }

  li {
    text-align: start;
  }
}

.centered-toolbar-title {
  display: flex;
  justify-content: center;
  align-items: center;

  ion-title {
    display: flex;
    justify-content: center;
    align-items: center;

  }
}


.custom-confirmation-alert {
  .alert-wrapper {
    border-radius: 15px !important;
    --background: var(--ion-background-color);
    color: var(--ion-text-color);
    align-items: center;
  }
}



.metrics-modal {
  .modal-wrapper {
    --min-width: 90%;
    --max-width: 90%;
    --border-radius: 10px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  /* or any styling to indicate it's disabled */
}