@font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
      url('/assets/fonts/poppins/Poppins-Regular.woff') format('woff');
    font-weight: 400;
    /* Regular weight */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/poppins/Poppins-Bold.woff2') format('woff2'),
      url('/assets/fonts/poppins/Poppins-Bold.woff') format('woff');
    font-weight: 700;
    /* Bold weight */
    font-style: bold;
  }
  
  @font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-Regular.woff2') format('woff2'),
      url('/assets/fonts/nunito/Nunito-Regular.woff') format('woff');
    font-weight: 400;
    /* Regular weight */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-Bold.woff2') format('woff2'),
      url('/assets/fonts/nunito/Nunito-Bold.woff') format('woff');
    font-weight: 700;
    /* Bold weight */
    font-style: bold;
  }