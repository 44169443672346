html {
  --ion-default-dynamic-font: -apple-system-body;
  --ion-font-family: var(--font-family-nunito);
  --ion-default-font: var(--font-family-nunito);
  font-family: var(--font-family-nunito);
  font-size: 16px;
}  

html.md {
  --ion-default-font: var(--font-family-nunito);
}

body {
  font-family: var(--font-family-nunito);
  color:var(--ion-color-dark);
}

h1{
  font-weight: 700;
  font-family: var(--font-family-poppins);
  
  font-size: 2rem;
  margin:0;
  padding:0;
  line-height: 42px;

  text-transform: none;

  @media (prefers-color-scheme: light) {
    color: var(--ion-color-primary);
  }
  @media (prefers-color-scheme: dark) {
    color: white;
  }
 
}



h1.secondary {
    color: var(--ion-color-secondary);
}

span.secondary {
  color: var(--ion-color-secondary);
}

p{
  color:var(--ion-color-dark);
}

h2{
  font-weight: 700;
  font-family: var(--font-family-poppins);
  
  font-size: 1.67rem;
  margin:0;
  padding:0;
  line-height: 36px;

  text-transform: none;
  @media (prefers-color-scheme: light) {
    color: var(--ion-color-primary);
  }
  @media (prefers-color-scheme: dark) {
    color: white;
  }
}

h2.secondary {
    color: var(--ion-color-secondary);
}

h2.thin {
 font-weight: normal;
}


h3{
  font-size: 1.2rem;
  margin:0;
  padding:0;
  text-transform: none;
  @media (prefers-color-scheme: light) {
    color: var(--ion-color-primary);
  }
  @media (prefers-color-scheme: dark) {
    color: white;
  }
}

h3.secondary {
    color: var(--ion-color-secondary);
}

small{
  font-size: 0.67rem;
  color: var(--ion-color-primary);
}

h4{
  font-size: 1rem;;
}

.consent-container{
  text-align: initial;
  h2{
    font-size: 1.5rem;
    font-family: var(--font-family-poppins);
    text-align: center;
    @media (prefers-color-scheme: light) {
      color: var(--ion-color-primary);
    }
    @media (prefers-color-scheme: dark) {
      color: white;
    }
    
  }
  h3{
    font-size: 1rem;
    font-weight: bold;
    font-family: var(--font-family-nunito);
    color: var(--ion-color-secondary);  
  }
  p{
    font-size: 1rem;
    font-family: var(--font-family-nunito);
  }
  li{
    color: var(--ion-color-dark); 
  }
} 

